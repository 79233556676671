import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../../../helpers/theme"

/**
 * Header - Container (Fixed)
 */
export const headerContainerStyles: SxStyleProp = {
  variant: "layout.formHeader",
  position: "relative",
  justifyContent: "space-between",
}

/**
 * Header - Inner (Box)
 */
export const headerInnerStyles: SxStyleProp = {
  variant: "layout.logo",
}

/**
 * Header - Navigaton (Flex - nav)
 */
export const headerNavigationStyles: SxStyleProp = {
  "> ul": {
    m: 0,
    justifyContent: "space-between",
    alignItems: "center",
    listStyleType: "none",
    "> li:not(:first-of-type)": {
      mt: "10px",
      mb: "10px",
      mr: [5, 5, 6, 6, 8],
    },
  },
}

/** @jsx jsx */
import {
  howToStepStyles,
  howToTextStyles,
  howToTitleStyles,
  howToStepIconStyles,
  howToStepTextStyles,
  howToContainerStyles,
  howToTextContainerStyles,
  howToStepContainerStyles,
} from "./styles"
import { Icon } from "./images"
import { useIntl } from "react-intl"
import { jsx, Flex, Styled } from "theme-ui"
import { FormUserProps } from "../../layouts/form"
import { translate, translateHtml } from "../../../helpers/grammar"

import PriorityHigh from "@material-ui/icons/PriorityHigh"

/**
 * Component rendering the "howTo" step/section in the main "Forms" component.
 *
 * 1. Title
 * 2. Text (HTML Based)
 * 3. Steps
 *   a) Icon
 *   b) Text
 *
 * Use on "forms" page.
 *
 * @param {FormUserProps} param
 *
 * @returns {JSX.Element}
 */
const HowTo = ({ userType }: FormUserProps): JSX.Element => {
  const intl = useIntl()

  const steps = [0, 1, 2].map(value =>
    translateHtml(intl, `forms.howTo.${userType}.steps.${value}`)
  )

  return (
    <Flex sx={howToContainerStyles}>
      {/* <Flex>
        <div className={"covidBanner inpage active"}>
          <div className="icon">
            <PriorityHigh />
          </div>
          <div className="txt">{translate(intl, `homepage.covid.text`)}</div>
          <div className="txt">{translate(intl, `homepage.covid.text2`)}</div>
        </div>
      </Flex> */}
      <Flex sx={howToTextContainerStyles}>
        <Styled.h3 sx={howToTitleStyles}>
          {translate(intl, `forms.howTo.${userType}.title`)}
        </Styled.h3>
        <Styled.p sx={howToTextStyles}>
          {translate(intl, `forms.howTo.${userType}.text`)}
        </Styled.p>
      </Flex>
      <Flex sx={howToStepContainerStyles}>
        {steps.length
          ? steps.map((step, i) => {
              return (
                <Flex key={i} sx={howToStepStyles}>
                  <Flex sx={howToStepIconStyles} className={"IconBox"}>
                    <Icon index={i} alt={`Step - ${i + 1}`} />
                  </Flex>
                  <Styled.p sx={howToStepTextStyles}>{step}</Styled.p>
                </Flex>
              )
            })
          : null}
      </Flex>
    </Flex>
  )
}

export default HowTo

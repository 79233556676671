/** @jsx jsx */
import {
  headerInnerStyles,
  headerContainerStyles,
  headerNavigationStyles,
} from "./styles"
import { Logo } from "./images"
import { useIntl } from "react-intl"
import { jsx, Flex, Box } from "theme-ui"
import { Link } from "gatsby-plugin-intl"
import main from "../../../../routes/main"
import { translate } from "../../../../helpers/grammar"

export interface HeaderProps {
  routeId: string
  title: string
}

const Header = ({ routeId, title }: HeaderProps) => {
  const intl = useIntl()
  const redirectionRoute = main({ intl }).find(route => route.id === routeId)

  return (
    <Flex sx={headerContainerStyles}>
      <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={headerInnerStyles}>
          <Link to="/">
            <Logo alt={title} />
          </Link>
        </Box>
      </Flex>
      <Flex as={"nav"} sx={headerNavigationStyles}>
        <Flex as={"ul"}>
          <Box as={"li"} sx={{ display: "inline-flex" }}>
            <Link
              sx={{ variant: "buttons.navigation" }}
              to={redirectionRoute ? redirectionRoute.path : "/"} // Redirect to home if no page
            >
              {translate(intl, "header.closeButton")}
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Header

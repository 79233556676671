import React from "react"
import { Icon as FirstIcon } from "./first"
import { Icon as ThirdIcon } from "./third"
import { Icon as SecondIcon } from "./second"
import { ImageProps } from "../../../../interfaces/image"

export { Icon as FirstIcon } from "./first"
export { Icon as ThirdIcon } from "./third"
export { Icon as SecondIcon } from "./second"

export const Icon = ({
  index,
  className,
  alt,
}: ImageProps & { index: number }): JSX.Element => {
  switch (index + 1) {
    case 2:
      return <SecondIcon className={className} alt={alt} />
    case 3:
      return <ThirdIcon className={className} alt={alt} />
    default:
      return <FirstIcon className={className} alt={alt} />
  }
}

/** @jsx jsx */
import {
  medicationsTextStyles,
  medicationsTitleStyles,
  medicationsContainerStyles,
  medicationsListContainerStyles,
  medicationsTextContainerStyles,
  medicationsListErrorTextStyles,
  medicationsSearchContainerStyles,
  medicationsListErrorContainerStyles,
} from "./styles"
import MedicationList from "./list"
import { useIntl } from "react-intl"
import { FormContext } from "../context"
import { CustomTextField } from "../../common"
import { FocusContext } from "../context/focus"
import { jsx, Styled, Flex, Box } from "theme-ui"
import { FormUserProps } from "../../layouts/form"
import { isActualMobileDevice } from "../../../helpers/others"
import { Disease, Medication } from "../../../interfaces/forms"
import { useEffect, useState, ChangeEvent, useContext } from "react"
import { translate, translateCustom } from "../../../helpers/grammar"
import ClipBoardIcon from "../../../images/common/icons/forms/medications/clip-board.inline.svg"

/**
 * Type definition for props required for "Medications" component.
 *
 * @interface
 */
export interface MedicationsProps {
  generalFormUrl: string
  medications: Medication[]
}

/**
 * Component rendering the "medications" step/section in the main "Forms" component.
 *
 * 1. Title
 * 2. Text
 * 3. Search Field
 * 4. Filtered List
 *
 * Use on "forms" page.
 *
 * @param {MedicationsProps & FormUserProps} param0
 *
 * @returns {JSX.Element}
 */
const Medications = ({
  generalFormUrl,
  medications,
  userType,
}: MedicationsProps & FormUserProps): JSX.Element => {
  const intl = useIntl()
  const minSearchLength = 3 // Minimum characters user needs to enter before filtering happens.
  const formContext = useContext(FormContext)
  const focusContext = useContext(FocusContext)

  const {
    onDiseaseSelect,
    selectedDisease,
    selectedMedication,
    searchedMedication,
    onMedicationSearch,
  } = formContext

  const [search, setSearch] = useState<string>(
    searchedMedication ? searchedMedication : ""
  )
  const [searchableMedications, setSearchableMedications] = useState<
    Medication[]
  >([])
  const [selectedDiseaseName, setSelectedDiseaseName] = useState<string>(
    selectedDisease ? selectedDisease.name : ""
  ) // For internal useEffect calls.
  const [selectedMedicationName, setSelectedMedicationName] = useState<string>(
    selectedMedication ? selectedMedication.name : ""
  ) // For internal useEffect calls.

  /**
   * On disease select from "MedicationsList", update
   * "selectedDisease" & "selectedMedication" in FormContext.
   *
   * @param {Medication} selectedMedicationItem
   * @param {Disease} selectedDiseaseItem
   *
   * @returns {void}
   */
  const onListItemSelect = (
    selectedMedicationItem: Medication,
    selectedDiseaseItem: Disease
  ): void => {
    setSelectedDiseaseName(selectedDiseaseItem.name)

    setSelectedMedicationName(selectedMedicationItem.name)

    onDiseaseSelect(selectedDiseaseItem, selectedMedicationItem)
  }

  /**
   * Filter searchable medications.
   *
   * @param {string} searchedString
   *
   * @returns {void}
   */
  const filterMedications = (searchedString: string): void => {
    const trimmedValue = searchedString
      .trim()
      .toLocaleLowerCase()
      .replace(/\\/, "\\\\") // Replace "backslashes" entered by user

    // Only allow search after `minSearchLength` characters
    if (!trimmedValue.length || trimmedValue.length < minSearchLength) {
      setSearchableMedications([])

      return
    }

    setSearchableMedications(
      medications.filter(medication => {
        return medication.name.toLocaleLowerCase().includes(trimmedValue)
      })
    )
  }

  /**
   * Handles "onChange" event for "CustomTextField" element.
   *
   * @param {ChangeEvent<HTMLInputElement>} e
   *
   * @returns {void}
   */
  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    const currentValue = e.currentTarget.value

    setSearch(currentValue)

    filterMedications(currentValue)
  }

  /**
   * On "selectedMedicationName" & "selectedDiseaseName" change,
   * re-filter medications & medication search.
   */
  useEffect(() => {
    if (selectedMedicationName.length && selectedDiseaseName.length) {
      filterMedications(search)

      onMedicationSearch(search)
    }
  }, [selectedMedicationName, selectedDiseaseName])

  return (
    <Flex sx={medicationsContainerStyles}>
      <Flex sx={medicationsTextContainerStyles}>
        <Styled.h3 sx={medicationsTitleStyles(window ? window.innerHeight : 0)}>
          {translate(intl, `forms.medications.${userType}.title`)}
        </Styled.h3>
        <Styled.p sx={medicationsTextStyles}>
          {translate(intl, `forms.medications.${userType}.text`)}
        </Styled.p>
      </Flex>
      <Flex sx={medicationsSearchContainerStyles}>
        <CustomTextField
          value={search}
          label={translate(intl, `forms.medications.${userType}.search`)}
          onChange={handleSearch}
          onFocus={
            e =>
              focusContext.setInputFocusState(
                isActualMobileDevice ? true : false
              ) // For keyboards in mobile
          }
          onBlur={e => focusContext.setInputFocusState(false)} // For keyboards in mobile
        />
        {searchableMedications.length ? (
          <Flex sx={medicationsListContainerStyles}>
            <MedicationList
              onListItemSelect={onListItemSelect}
              medications={searchableMedications}
              selectedDiseaseName={selectedDiseaseName}
              selectedMedicationName={selectedMedicationName}
            />
          </Flex>
        ) : search.length >= minSearchLength ? ( // Check if user actually searched for something
          <Flex sx={medicationsListErrorContainerStyles}>
            <Box>
              <ClipBoardIcon />
            </Box>
            <Styled.p sx={medicationsListErrorTextStyles}>
              {translateCustom(intl, `forms.medications.${userType}.error`, {
                searchedText: (
                  <span key={0} className={"SearchText"}>
                    {search}
                  </span>
                ),
                formLink: (
                  <a
                    key={1}
                    href={generalFormUrl}
                    sx={{
                      color: "primary",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                    target={"_blank"}
                  >
                    {translate(intl, `forms.medications.${userType}.formLink`)}
                  </a>
                ),
              })}
            </Styled.p>
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default Medications

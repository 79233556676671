import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../../helpers/theme"

export const downloadContainerStyles: SxStyleProp = {
  height: "100%",
  overflow: "hidden",
  alignItems: "center",
  flexDirection: "column",
  py: createResponsiveStyles(6, "5%"),
  mb: createResponsiveStyles(6, 9),
  width: createResponsiveStyles("100%", "90%", "80%"),
}

export const downloadTextContainerStyles: SxStyleProp = {
  width: "100%",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
}

export const downloadTitleStyles: SxStyleProp = {
  pb: 2,
  textAlign: "center",
  fontWeight: "bold",
  fontFamily: "heading",
  fontSize: createResponsiveStyles(4, 6),
}

export const downloadTextStyles: SxStyleProp = {
  px: 2,
  textAlign: "center",
  pb: createResponsiveStyles(2, 7),
  width: createResponsiveStyles("100%", "100%", "60%"),
}

export const downloadCTAContainerStyles: SxStyleProp = {
  borderWidth: "2px",
  borderStyle: "solid",
  borderRadius: "30px",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  borderColor: "formBorder",
  py: createResponsiveStyles(4, 6),
  mt: createResponsiveStyles(6, 6),
  mb: createResponsiveStyles(8, 6),
  width: createResponsiveStyles("85%", "80%", "60%"),
  "& > p": {
    textAlign: "center",
    width: "100%",
    pb: 3,
    px: 2,
  },
}

export const downloadCheckboxContainerStyles: SxStyleProp = {
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
}

export const downloadCheckboxInnerStyles: SxStyleProp = {
  width: ["86%", "76%", "76%", "40%", "33%", "34%"],
}

export const downloadCheckboxStyles: SxStyleProp = {
  color: "inputPlaceholder",
  "& > label": {
    m: 0,
  },
  mb: "-5px",
  "&&": {
    mr: "0",
  },
}

export const downloadEmailInputContainerStyles: SxStyleProp = {
  width: "100%",
  px: createResponsiveStyles(4, 8),
}

export const downloadEmailInputStyles: SxStyleProp = {
  "&&": {
    mb: 4,
    "& .MuiAutocomplete-inputRoot": {
      overflowX: "hidden",
      minHeight: ["50px", "auto"],
      "& .MuiAutocomplete-input": {
        minWidth: "60px",
      },
      "& > .MuiAutocomplete-tag": {
        minHeight: "32px",
        height: "auto",
        "& > span": {
          wordBreak: "break-word",
          whiteSpace: "pre-wrap",
        },
      },
    },
  },
  "& > div": {
    m: 0,
    "& > div > div[role='button']": {
      color: "white",
      backgroundColor: "primary",
      my: 2,
      "& > svg": {
        color: "white",
      },
    },
  },
}

export const downloadButtonContainerStyles: SxStyleProp = {
  width: "100%",
  textAlign: "center",
}

export const downloadButtonStyles: SxStyleProp = {
  variant: "buttons.download",
}

export const downloadCheckboxErrorStyles: SxStyleProp = {
  "&&": {
    pt: 1,
    textAlign: "center",
    fontFamily: "body",
  },
}

export const downloadFormErrorStyles: SxStyleProp = {
  "&&": {
    pt: 4,
    textAlign: "center",
    fontFamily: "body",
  },
}

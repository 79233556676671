/** @jsx jsx */
import { jsx } from "theme-ui"
import List from "@material-ui/core/List"
import { Medication } from "../../../../interfaces/forms"
import MedicationListItem, { MedicationListItemSelectableProps } from "./item"

/**
 * Type definition for props required for "MedicationList" component.
 *
 * @interface
 */
interface MedicationListProps {
  medications: Medication[]
}

/**
 * Type definition for props required for "MedicationListSelectable" component.
 *
 * @interface
 */
interface MedicationListSelectableProps
  extends MedicationListItemSelectableProps {
  selectedMedicationName: string
  selectedDiseaseName: string
}

/**
 * Component rendering the "medications" step/section in the main "Medication" component.
 *
 * 1. List
 * 2. List Items
 *
 * Use on "forms" page.
 *
 * @param {MedicationListProps & MedicationListSelectableProps} param
 *
 * @returns {JSX.Element}
 */
const MedicationList = ({
  medications,
  onListItemSelect,
  selectedMedicationName,
  selectedDiseaseName,
}: MedicationListProps & MedicationListSelectableProps) => {
  return (
    <List
      component="nav"
      disablePadding={true}
      aria-labelledby="nested-list-subheader"
      sx={{ width: "100%", flexBasis: "100%" }}
    >
      {medications.map((medication, i) => (
        <MedicationListItem
          onListItemSelect={onListItemSelect}
          medication={medication}
          selectedDiseaseName={
            selectedMedicationName && selectedMedicationName === medication.name
              ? selectedDiseaseName
              : null
          }
          key={i}
        />
      ))}
    </List>
  )
}

export default MedicationList

import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../../../helpers/theme"

export const footerContainerStyles = (hide: boolean = false): SxStyleProp => {
  return {
    left: 0,
    bottom: 0,
    top: "auto",
    width: "100%",
    bg: "whiteTwo",
    zIndex: "footer",
    flexDirection: "column",
    display: hide ? "none" : "flex",
    pt: createResponsiveStyles("0", 5, 6),
    pb: createResponsiveStyles("0", 3, 4),
    px: createResponsiveStyles("0", 7, 8), // Matches the form header
  }
}

export const footerProgressBarContainerStyles: SxStyleProp = {
  flexGrow: 1,
  width: "100%",
  display: createResponsiveStyles("none", "flex"),
  "& .MuiLinearProgress-root": {
    width: "100%",
    height: "12px",
  },
  "& .MuiLinearProgress-bar": {
    borderRadius: "40px",
  },
}

export const footerInnerStyles: SxStyleProp = {
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
}

export const footerButtonOneStyles: SxStyleProp = {
  bg: "whiteTwo",
  variant: "buttons.secondary",
  width: createResponsiveStyles("50%", "216px"),
  borderRadius: createResponsiveStyles("0", "3px"),
}

export const footerContentStyles: SxStyleProp = {
  width: "40%",
  alignItems: "center",
  justifyContent: "center",
  display: createResponsiveStyles("none", "flex", "flex"),
  "> p": {
    pl: 8,
    fontSize: 1,
    color: "primary",
    fontWeight: "dark",
    fontFamily: "heading",
    display: createResponsiveStyles("none", "none", "block"),
  },
  svg: {
    path: {
      fill: "primary",
    },
  },
}

export const footerButtonTwoStyles: SxStyleProp = {
  variant: "buttons.primary",
  width: createResponsiveStyles("50%", "216px"),
  borderRadius: createResponsiveStyles("0", "4px"),
}

import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../../helpers/theme"

export const groupContainerStyles: SxStyleProp = {
  height: "100%",
  overflow: "hidden",
  alignItems: "center",
  flexDirection: "column",
  py: createResponsiveStyles(6, 9),
  width: createResponsiveStyles("100%", "90%", "80%"),
}

export const groupTextContainerStyles: SxStyleProp = {
  width: "100%",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
}

export const groupTitleStyles: SxStyleProp = {
  pb: 2,
  textAlign: "center",
  fontWeight: "bold",
  fontFamily: "heading",
  fontSize: createResponsiveStyles(4, 6),
}

export const groupTextStyles: SxStyleProp = {
  px: 2,
  textAlign: "center",
  pb: createResponsiveStyles(2, 8),
  width: createResponsiveStyles("100%", "100%", "60%"),
}

export const groupSelectInputContainerStyles: SxStyleProp = {
  pt: 4,
  px: createResponsiveStyles(4, 6, 6),
  width: createResponsiveStyles("100%", "100%", "70%"),
}

export const groupSelectInputStyles: SxStyleProp = {
  width: "100%",
}

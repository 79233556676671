/** @jsx jsx */
import {
  useRef,
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  KeyboardEvent,
  FocusEvent,
} from "react"
import {
  downloadTextStyles,
  downloadTitleStyles,
  downloadButtonStyles,
  downloadCheckboxStyles,
  downloadContainerStyles,
  downloadFormErrorStyles,
  downloadEmailInputStyles,
  downloadCTAContainerStyles,
  downloadTextContainerStyles,
  downloadCheckboxErrorStyles,
  downloadCheckboxInnerStyles,
  downloadButtonContainerStyles,
  downloadCheckboxContainerStyles,
  downloadEmailInputContainerStyles,
} from "./styles"
import {
  CustomCheckbox,
  CustomTextField,
  CustomAutocomplete,
  CustomFormControlLabel,
} from "../../common"
import {
  environmentVariable,
  isActualMobileDevice,
} from "../../../helpers/others"
import { useIntl } from "react-intl"
import { FormContext } from "../context"
import { FocusContext } from "../context/focus"
import { jsx, Flex, Styled, Box } from "theme-ui"
import { FormHelperText } from "@material-ui/core"
import { FormUserProps } from "../../layouts/form"
import { sendFormFields } from "../../../utils/api"
import { translate } from "../../../helpers/grammar"

/**
 * Component rendering the "download" step/section in the main "Forms" component.
 *
 * 1. Title
 * 2. Checkbox
 *   a) Email
 *     - Input Field (Autocomplete)
 *   b) Download
 *   c) UBC (Environment Variable Based)
 *
 * Use on "forms" page.
 *
 * @param {FormUserProps} param
 *
 * @returns {JSX.Element}
 */
const Download = ({ userType }: FormUserProps): JSX.Element => {
  const intl = useIntl()

  // Get context values
  const formContext = useContext(FormContext)
  const focusContext = useContext(FocusContext)

  // Field errors
  const [formError, setFormError] = useState<string>("")
  const [emailsError, setEmailsError] = useState<string>("")
  const [checkboxError, setCheckboxError] = useState<string>("")

  // Autocomplete "CustomTextField" input value
  const [emailsInputValue, setEmailsInputValue] = useState<string>("")

  // Used for the anchor link actioning on file download
  const downloadLinkElement = useRef<HTMLAnchorElement | null>(null)

  const {
    isCompleted,
    sendToEmails,
    isCompleting,
    allowDownload,
    selectedDisease,
    allowSendToEmail,
    isUBCMember,
    onChangeSendToEmails,
    changeEnabledNextStep,
    onChangeAllowDownload,
    onChangeIsUBCMember,
    changeIsCompletedState,
    changeIsCompletingState,
    onChangeAllowSendToEmail,
  } = formContext

  /**
   * Event handler for "CustomCheckbox" related to the field "allowSendToEmail"
   *
   * @function
   *
   * @param {ChangeEvent<HTMLInputElement>} e
   *
   * @returns {void}
   */
  const onCheckEmail = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.currentTarget.checked) {
      setCheckboxError("")
    }

    onChangeAllowSendToEmail(e)
  }

  /**
   * Event handler for "CustomCheckbox" related to the field "allowDownload"
   *
   * @function
   *
   * @param {ChangeEvent<HTMLInputElement>} e
   *
   * @returns {void}
   */
  const onCheckDownload = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.currentTarget.checked) {
      setCheckboxError("")
    }

    onChangeAllowDownload(e)
  }

  /**
   * Event handler for "CustomCheckbox" related to the field "isUBCMember"
   *
   * @function
   *
   * @param {ChangeEvent<HTMLInputElement>} e
   *
   * @returns {void}
   */
  const onCheckIsUBCMember = (e: ChangeEvent<HTMLInputElement>): void => {
    onChangeIsUBCMember(e)
  }

  /**
   * Even handler for download button. Makes an API call
   * only if "allowSendToEmail" is checked.
   *
   * @function
   *
   * @returns {Promise<void>}
   */
  const onClickButton = async (): Promise<void> => {
    // If none of the checkboxes are checked, update "checkboxError"
    if (!allowSendToEmail && !allowDownload) {
      setCheckboxError(
        translate(intl, `forms.download.${userType}.error.checkbox`)
      )

      return
    }

    // Validate "CustomAutocomplete" field
    if (!validateAutocomplete()) {
      return
    }

    // If no disease found, there'd be nothing to download
    if (!selectedDisease) {
      return
    }

    changeIsCompletingState(true)

    if (allowSendToEmail) {
      await sendFormFields({
        emails: sendToEmails,
        form: isUBCMember
          ? selectedDisease.ubcFormUrl
          : selectedDisease.formUrl,
        isUBCMember,
        language: intl.locale,
      })
        .then(() => {
          markStepAsCompleted()
        })
        .catch(() => {
          changeIsCompletingState(false)
          setFormError(translate(intl, `forms.download.${userType}.error.main`))
        })
    } else {
      markStepAsCompleted()
    }
  }

  /**
   * Considering this is the final step, set use progress as completed.
   *
   * @function
   */
  const markStepAsCompleted = () => {
    setTimeout(() => {
      setFormError("")
      changeIsCompletingState(false)
      changeIsCompletedState(true)
      changeEnabledNextStep()
    }, 1000)
  }

  // Reset "formError" if any change is made to input fields.
  useEffect(() => {
    setFormError("")
  }, [
    isCompleted,
    sendToEmails,
    checkboxError,
    allowDownload,
    allowSendToEmail,
  ])

  // Dynamically click on the anchor link to initiate form download.
  useEffect(() => {
    if (isCompleting && allowDownload && selectedDisease) {
      const { current } = downloadLinkElement
      if (current) {
        current.click()
      }
    }
  }, [isCompleting])

  /**
   * Event handle for "CustomAutocomplete" field.
   *
   * @function
   *
   * @param {ChangeEvent<{}>} e
   * @param {string[]} input
   *
   * @returns {void}
   */
  const onChangeAutocomplete = (e: ChangeEvent<{}>, input: string[]): void => {
    // Check if email already exists in list
    if (sendToEmails.find(value => value === emailsInputValue)) {
      return
    }

    validateAutocomplete(input)

    onChangeSendToEmails(input)
  }

  /**
   * When a use presses "spacebar" or "tab", update "sendToEmails"
   *
   * @function
   *
   * @param {KeyboardEvent<HTMLInputElement>} e
   *
   * @returns {void}
   */
  const onInputChangeAutocomplete = (
    e: KeyboardEvent<HTMLInputElement>
  ): void => {
    if (e.key === " " || e.key === "Spacebar" || e.key === "Tab") {
      e.preventDefault()

      onChangeSendToEmails([...sendToEmails, emailsInputValue])
    }
  }

  /**
   * Validates the inputs for "CustomAutocomplete" field.
   *
   * @function
   *
   * @param {string[]} input
   *
   * @returns {boolean}
   */
  const validateAutocomplete = (input?: string[]): boolean => {
    const inputToValidate: string[] = input || sendToEmails

    if (allowSendToEmail && !inputToValidate.length) {
      setEmailsError(translate(intl, `forms.download.${userType}.error.emails`))

      return false
    } else {
      setEmailsError("")

      return true
    }
  }

  /**
   * OnBlur event handler for "CustomAutocomplete" field.
   *
   * @function
   *
   * @param {FocusEvent<{}>} e
   *
   * @returns {void}
   */
  const onBlurAutocomplete = (e: FocusEvent<{}>): void => {
    onChangeSendToEmails([...sendToEmails, emailsInputValue])

    // Reset Focus Context (for Footer buttons)
    focusContext.setInputFocusState(false)
  }

  return (
    <Flex sx={downloadContainerStyles}>
      <Flex sx={downloadTextContainerStyles}>
        <Styled.h3 sx={downloadTitleStyles}>
          {translate(intl, `forms.download.${userType}.title`)}
        </Styled.h3>
        <Styled.p sx={downloadTextStyles}>
          {translate(intl, `forms.download.${userType}.text`)}
        </Styled.p>
      </Flex>
      <Flex sx={downloadCTAContainerStyles}>
        <Styled.p>
          {translate(intl, `forms.download.${userType}.request`)}{" "}
          <strong key={0}>{selectedDisease ? selectedDisease.name : ""}</strong>
        </Styled.p>
        <Flex sx={downloadCheckboxContainerStyles}>
          <Box sx={downloadCheckboxInnerStyles}>
            <CustomFormControlLabel
              sx={downloadCheckboxStyles}
              control={
                <CustomCheckbox
                  color="primary"
                  checked={allowSendToEmail}
                  value={allowSendToEmail}
                  onChange={onCheckEmail}
                />
              }
              label={translate(intl, `forms.download.${userType}.email`)}
            />
          </Box>
        </Flex>
        {formContext.allowSendToEmail ? (
          <Box sx={downloadEmailInputContainerStyles}>
            <CustomAutocomplete
              sx={downloadEmailInputStyles}
              multiple={true}
              freeSolo={true}
              defaultValue={[]}
              autoSelect={true}
              autoComplete={true}
              options={[]}
              value={sendToEmails}
              inputValue={emailsInputValue}
              onInputChange={(e, v) => setEmailsInputValue(v)}
              onChange={(e, v) => onChangeAutocomplete(e, v)}
              onFocus={e =>
                focusContext.setInputFocusState(
                  isActualMobileDevice ? true : false
                )
              }
              onBlur={onBlurAutocomplete}
              renderInput={params => (
                <CustomTextField
                  {...params}
                  error={emailsError.length ? true : false}
                  helperText={emailsError}
                  required={allowSendToEmail}
                  margin={"normal"}
                  fullWidth={true}
                  onKeyDown={onInputChangeAutocomplete}
                  label={translate(
                    intl,
                    `forms.download.${userType}.emailField`
                  )}
                />
              )}
            />
          </Box>
        ) : null}
        <Flex sx={downloadCheckboxContainerStyles}>
          <Box sx={downloadCheckboxInnerStyles}>
            <CustomFormControlLabel
              sx={downloadCheckboxStyles}
              control={
                <CustomCheckbox
                  color="primary"
                  checked={allowDownload}
                  value={allowDownload}
                  onChange={onCheckDownload}
                />
              }
              label={translate(intl, `forms.download.${userType}.download`)}
            />
          </Box>
        </Flex>
        {environmentVariable("enableUBCCheckbox") ? (
          <Flex sx={downloadCheckboxContainerStyles}>
            <Box sx={downloadCheckboxInnerStyles}>
              <CustomFormControlLabel
                sx={downloadCheckboxStyles}
                control={
                  <CustomCheckbox
                    color="primary"
                    checked={isUBCMember}
                    value={isUBCMember}
                    onChange={onCheckIsUBCMember}
                  />
                }
                label={translate(intl, `forms.download.${userType}.ubc`)}
              />
            </Box>
          </Flex>
        ) : null}
        <Box sx={downloadButtonContainerStyles}>
          {checkboxError.length ? (
            <FormHelperText sx={downloadCheckboxErrorStyles} error={true}>
              {checkboxError}
            </FormHelperText>
          ) : null}
          <button
            onClick={onClickButton}
            sx={downloadButtonStyles}
            disabled={isCompleting || (!allowDownload && !allowSendToEmail)}
            className={
              isCompleting || (!allowDownload && !allowSendToEmail)
                ? "Disabled"
                : ""
            }
          >
            {translate(intl, `forms.download.${userType}.button`)}
          </button>
          {formError.length ? (
            <FormHelperText sx={downloadFormErrorStyles} error={true}>
              {formError}
            </FormHelperText>
          ) : null}
        </Box>
        {isCompleting && selectedDisease ? (
          <a
            ref={downloadLinkElement}
            href={
              isUBCMember ? selectedDisease.ubcFormUrl : selectedDisease.formUrl
            }
            sx={{ visibility: "hidden" }}
            target={"_blank"}
          >
            Download {selectedDisease.name}
          </a>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default Download

/** @jsx jsx */
import {
  medicationListItemStyles,
  medicationListItemParentStyles,
} from "./styles"
import { jsx } from "theme-ui"
import List from "@material-ui/core/List"
import { Fragment, useState } from "react"
import Collapse from "@material-ui/core/Collapse"
import ListItem from "@material-ui/core/ListItem"
import { CustomListItemText } from "../../../../common"
import { Medication, Disease } from "../../../../../interfaces/forms"
import CaretIcon from "../../../../../images/common/icons/forms/medications/caret.inline.svg"

/**
 * Type definition for props required for "MedicationListItem" component.
 *
 * @interface
 */
interface MedicationListItemProps {
  medication: Medication
  selectedDiseaseName?: string | null
}

/**
 * Type definition for selectable props required for "MedicationListItem" component.
 *
 * @interface
 */
export interface MedicationListItemSelectableProps {
  onListItemSelect: (
    selectedMedication: Medication,
    selectedDisease: Disease
  ) => void
}

/**
 * Component rendering the "medications" step/section in the main "Medication" component.
 *
 * 1. List Item
 * 2. Children of List Item
 *
 * Use on "forms" page.
 *
 * @param {MedicationListItemProps & MedicationListItemSelectableProps} param
 *
 * @returns {JSX.Element}
 */
const MedicationListItem = ({
  onListItemSelect,
  medication,
  selectedDiseaseName,
}: MedicationListItemProps &
  MedicationListItemSelectableProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(!!selectedDiseaseName)

  /**
   * Handles "onClick" event for "CaretIcon".
   */
  const handleExandCollapseClick = () => {
    setIsOpen(!isOpen)
  }

  /**
   * Handles "onClick" event for child list item click.
   *
   * @param {Disease} selectedDisease
   */
  const handleListItemClick = (selectedDisease: Disease) => {
    onListItemSelect(medication, selectedDisease)
  }

  // If no children, return without "CaretIcon" & children
  if (!medication.diseases.length) {
    return (
      <ListItem sx={medicationListItemStyles}>
        <CustomListItemText primary={medication.name} />
      </ListItem>
    )
  }

  return (
    <Fragment>
      <ListItem
        sx={medicationListItemParentStyles(isOpen)}
        onClick={handleExandCollapseClick}
      >
        <CustomListItemText primary={medication.name} />
        {isOpen ? (
          <CaretIcon sx={{ flexShrink: 0 }} />
        ) : (
          <CaretIcon sx={{ flexShrink: 0, transform: "rotate(180deg)" }} />
        )}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit={true}>
        <List component="div">
          {medication.diseases.map((disease, k) => (
            <ListItem
              sx={medicationListItemStyles}
              className={selectedDiseaseName === disease.name ? "Selected" : ""}
              key={k}
              button={true}
              onClick={e => handleListItemClick(disease)}
            >
              <CustomListItemText primary={disease.name} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Fragment>
  )
}

export default MedicationListItem

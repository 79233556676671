import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../../../../helpers/theme"

export const medicationListItemParentStyles = (
  isOpen: boolean = false
): SxStyleProp => ({
  width: "100%",
  cursor: "pointer",
  ":last-of-type": {
    // iOS Fix
    // Fix for last element hiding behind buttons in mobile screens
    mb: createResponsiveStyles(isOpen ? "0" : "48px", "0"), // Removing margin if container is open and given margin to child
    "~ .MuiCollapse-container": {
      mb: createResponsiveStyles("48px", "0"), // 48px = bottom button height
    },
  },
})

export const medicationListItemStyles: SxStyleProp = {
  width: "100%",
  "&.MuiListItem-button": {
    ":hover": {
      color: "text",
      backgroundColor: "duckEggBlue",
    },
  },
  "&&": {
    paddingLeft: 7,
    "&.Selected": {
      color: "white",
      fontWeight: "bold",
      backgroundColor: "primary",
    },
  },
}

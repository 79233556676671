/** @jsx jsx */
import {
  groupTextStyles,
  groupTitleStyles,
  groupContainerStyles,
  groupSelectInputStyles,
  groupTextContainerStyles,
  groupSelectInputContainerStyles,
} from "./styles"
import { useIntl } from "react-intl"
import { FormContext } from "../context"
import { jsx, Flex, Styled } from "theme-ui"
import { FocusContext } from "../context/focus"
import { FormUserProps } from "../../layouts/form"
import { ChangeEvent, useContext, useState } from "react"
import { isActualMobileDevice } from "../../../helpers/others"
import { CustomAutocomplete, CustomTextField } from "../../common"
import { translateHtml, translate } from "../../../helpers/grammar"

/**
 * Component rendering the "howTo" step/section in the main "Forms" component.
 *
 * 1. Title
 * 2. Text (HTML Based)
 * 3. Steps
 *   a) Icon
 *   b) Text
 *
 * Use on "forms" page.
 *
 * @param {FormUserProps} param
 *
 * @returns {JSX.Element}
 */
const Group = ({ userType }: FormUserProps): JSX.Element => {
  const intl = useIntl()
  const formContext = useContext(FormContext)
  const focusContext = useContext(FocusContext)
  const [groupSelectError, setGroupSelectError] = useState<string>("")

  const {
    selectedGroup,
    onChangeSelectedGroup,
    changeEnabledNextStep,
  } = formContext

  
  /**
   * Loops through a groups
   * NOTE: Only max 10 groups will be displayed
   */
  const getGroups = () => {
    const possibleGroups = []
    const counter = Array(10);

    for (let index = 0; index < counter.length; index++) {
      const id = `forms.group.${userType}.groups.${index}`
      
      if(intl.messages[id]) {
        possibleGroups.push(translateHtml(intl, id))
      } else {
        break;
      }
    }

    return possibleGroups
  }

  const groups = getGroups();

  const onChangeGroupSelect = (e: ChangeEvent<{}>, input: string): void => {
    const trimmedValue = input ? input.trim() : null

    if (!trimmedValue) {
      setGroupSelectError(translate(intl, `forms.group.${userType}.error`))

      changeEnabledNextStep(false)

      return
    } else {
      setGroupSelectError("")
    }

    onChangeSelectedGroup(trimmedValue)

    changeEnabledNextStep()
  }

  return (
    <Flex sx={groupContainerStyles}>
      <Flex sx={groupTextContainerStyles}>
        <Styled.h3 sx={groupTitleStyles}>
          {translate(intl, `forms.group.${userType}.title`)}
        </Styled.h3>
        <Styled.p sx={groupTextStyles}>
          {translate(intl, `forms.group.${userType}.text`)}
        </Styled.p>
      </Flex>
      <Flex sx={groupSelectInputContainerStyles}>
        <CustomAutocomplete
          sx={groupSelectInputStyles}
          options={groups}
          disableClearable={true}
          value={selectedGroup}
          onChange={(e, v) => onChangeGroupSelect(e, v)}
          onFocus={e =>
            focusContext.setInputFocusState(isActualMobileDevice ? true : false)
          }
          onBlur={e => focusContext.setInputFocusState(false)}
          renderInput={params => (
            <CustomTextField
              {...params}
              error={groupSelectError.length ? true : false}
              helperText={groupSelectError}
              label={translate(intl, `forms.group.${userType}.field`)}
              fullWidth={true}
            />
          )}
        />
      </Flex>
    </Flex>
  )
}

export default Group

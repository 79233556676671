/** @jsx jsx */
import Group from "./group"
import HowTo from "./how-to"
import Download from "./download"
import { jsx, Flex } from "theme-ui"
import { useIntl } from "react-intl"
import Medications from "./medications"
import { FormContext } from "./context"
import { FormUserProps } from "../layouts/form"
import { formsContainerStyles } from "./styles"
import { getMedications } from "../../utils/api"
import { translate } from "../../helpers/grammar"
import { useState, useEffect, useContext } from "react"
import { Medication, Disease } from "../../interfaces/forms"

/**
 * Component rendering all form steps/sections related to
 * downloading of a form.
 *
 * @param {FormUserProps} param
 *
 * @returns {JSX.Element}
 */
const Forms = ({ userType }: FormUserProps): JSX.Element => {
  const intl = useIntl()
  const formContext = useContext(FormContext)
  const [medications, setMedications] = useState<Medication[]>([])
  const [generalFormUrl, setGeneralFormUrl] = useState<string>("#")

  /**
   * On component mount, get all medications from the API.
   */
  useEffect(() => {
    let apiMedications: Medication[] = []

    const getMedicationsFromApi = async () => {
      apiMedications = await getMedications(intl.locale)

      setMedications(apiMedications)
    }

    getMedicationsFromApi()

    return () => {
      apiMedications = []
    }
  }, [])

  /**
   * On "medications" state change, look for the general
   * form again and set "generalFormUrl" value.
   */
  useEffect(() => {
    const medicationsWithGeneralForm = medications.filter(medication =>
      getDiseaseWithGeneralForm(medication)
    )

    if (!medicationsWithGeneralForm.length) {
      return
    }

    const diseaseWithGeneralForm = getDiseaseWithGeneralForm(
      medicationsWithGeneralForm[0]
    )

    if (diseaseWithGeneralForm) {
      setGeneralFormUrl(diseaseWithGeneralForm.formUrl)

      return
    }
  }, [medications])

  /**
   * Check if the "medication" has a general form inside
   * on of its "diseases".
   *
   * @param {Medication} medication
   *
   * @returns {Disease|undefined}
   */
  const getDiseaseWithGeneralForm = (
    medication: Medication
  ): Disease | undefined => {
    const generalFormName = translate(
      intl,
      `forms.medications.${userType}.generalRequestFormName`
    )

    return medication.diseases.find(
      disease =>
        disease.name === (generalFormName.toUpperCase() || "GENERAL REQUEST")
    )
  }

  return (
    <Flex sx={formsContainerStyles(formContext.activeStepId)}>
      {formContext.activeStepId === "group" ? (
        <Group userType={userType} />
      ) : formContext.activeStepId === "medications" ? (
        <Medications
          userType={userType}
          medications={medications}
          generalFormUrl={generalFormUrl}
        />
      ) : formContext.activeStepId === "howTo" ? (
        <HowTo userType={userType} />
      ) : formContext.activeStepId === "download" ? (
        <Download userType={userType} />
      ) : null}
    </Flex>
  )
}

export default Forms

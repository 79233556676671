import React from "react"
import SEO from "../components/seo"
import { useIntl } from "react-intl"
import { translate } from "../helpers/grammar"
import { WindowLocation } from "@reach/router"
import FormLayout from "../components/layouts/form"

/**
 * Type definition for required props for the component.
 *
 * @interface
 */
interface FormsProps {
  location: WindowLocation
}

/**
 * Component rendering the "/forms" page of the site.
 *
 * @param {FormsProps} param
 *
 * @returns {JSX.Element}
 */
const Forms = ({ location }: FormsProps): JSX.Element => {
  const intl = useIntl()

  // Translate text content for the page
  const pageTitle = translate(intl, "forms.title")

  // Render the component using "FormLayout"
  return (
    <FormLayout location={location}>
      <SEO title={pageTitle} />
    </FormLayout>
  )
}

export default Forms

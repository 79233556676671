/** @jsx jsx */
import {
  footerInnerStyles,
  footerContentStyles,
  footerContainerStyles,
  footerButtonOneStyles,
  footerButtonTwoStyles,
  footerProgressBarContainerStyles,
} from "./styles"
import { FormUserProps } from ".."
import { useIntl } from "react-intl"
import { CheckIcon } from "./images"
import { Link } from "gatsby-plugin-intl"
import { jsx, Flex, Box, Styled } from "theme-ui"
import { homeRoute } from "../../../../routes/main"
import { FormContext } from "../../../forms/context"
import { translate } from "../../../../helpers/grammar"
import { useContext, useState, useEffect } from "react"
import { FocusContext } from "../../../forms/context/focus"
import { Fixed, CustomLinearProgress, Absolute } from "../../../common"

const Footer = ({ userType }: FormUserProps) => {
  const intl = useIntl()
  const homeUrl = homeRoute({ intl })
  const formContext = useContext(FormContext)
  const focusContext = useContext(FocusContext)
  const [progressBarValue, setProgressBarValue] = useState<number>(0)

  const {
    goToNextStep,
    goToPreviousStep,
    totalSteps,
    activeStepId,
    activeStepNumber,
    enabledNextStep,
    isCompleting,
    isCompleted,
    changeIsCompletedState,
    changeIsCompletingState,
  } = formContext

  const { isFocused, isKeyboardOpen } = focusContext

  const onClickNext = () => {
    goToNextStep()
  }

  const onClickPrev = () => {
    changeIsCompletedState(false)
    changeIsCompletingState(false)

    goToPreviousStep()
  }

  useEffect(() => {
    if (isCompleted) {
      setProgressBarValue(100)
    } else if (totalSteps) {
      setProgressBarValue((activeStepNumber / totalSteps) * 100)
    }
  }, [totalSteps, activeStepNumber, isCompleted])

  return activeStepId.length ? (
    <Fixed sx={footerContainerStyles(isFocused && isKeyboardOpen)}>
      <Absolute sx={footerProgressBarContainerStyles}>
        <CustomLinearProgress color="primary" value={progressBarValue} />
      </Absolute>
      <Flex sx={footerInnerStyles}>
        <button
          sx={footerButtonOneStyles}
          disabled={!activeStepNumber}
          className={!activeStepNumber ? "Disabled" : ""}
          onClick={onClickPrev}
        >
          {translate(intl, `forms.${activeStepId}.${userType}.previousStep`)}
        </button>
        {isCompleted ? (
          <Flex sx={footerContentStyles}>
            <Box>
              <CheckIcon alt={"Success Icon"} />
            </Box>
            <Styled.p>
              {translate(intl, `forms.success.${userType}.text`)}
            </Styled.p>
          </Flex>
        ) : null}
        {isCompleted ? (
          <Link sx={footerButtonTwoStyles} to={homeUrl.path}>
            {translate(intl, `forms.success.${userType}.nextStep`)}
          </Link>
        ) : (
          <button
            sx={footerButtonTwoStyles}
            disabled={!enabledNextStep || isCompleting}
            className={!enabledNextStep ? "Disabled" : ""}
            onClick={onClickNext}
          >
            {translate(intl, `forms.${activeStepId}.${userType}.nextStep`)}
          </button>
        )}
      </Flex>
    </Fixed>
  ) : null
}

export default Footer

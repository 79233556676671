import React, {
  useState,
  useEffect,
  ReactNode,
  ChangeEvent,
  createContext,
} from "react"
import { validateEmail } from "../../../helpers/string"
import { environmentVariable } from "../../../helpers/others"
import { Disease, Medication } from "../../../interfaces/forms"

/**
 * Type definition for initial "FormProvider" state.
 *
 * @interface
 */
export interface FormContextState {
  totalSteps: number
  activeStepId: string
  activeStepNumber: number
  enabledNextStep: boolean
  selectedGroup: string
  onChangeSelectedGroup: (input: string) => void
  selectedDisease: Disease | null
  selectedMedication: Medication | null
  searchedMedication: string
  onDiseaseSelect: (
    disease: Disease | null,
    Medication: Medication | null
  ) => void
  onMedicationSearch: (input: string) => void
  allowSendToEmail: boolean
  allowDownload: boolean
  sendToEmails: string[]
  isUBCMember: boolean
  onChangeAllowSendToEmail: (e: ChangeEvent<HTMLInputElement>) => void
  onChangeAllowDownload: (e: ChangeEvent<HTMLInputElement>) => void
  onChangeSendToEmails: (emails: string[]) => void
  onChangeIsUBCMember: (e: ChangeEvent<HTMLInputElement>) => void
  changeEnabledNextStep: (input?: boolean) => void
  goToNextStep: () => void
  goToPreviousStep: () => void
  routeId: string
  isCompleting: boolean
  isCompleted: boolean
  changeIsCompletedState: (input: boolean) => void
  changeIsCompletingState: (input: boolean) => void
}

/**
 * Constant with initial "FormProvider" state.
 *
 * @constant
 */
const initialState: FormContextState = {
  totalSteps: 0,
  activeStepId: "",
  activeStepNumber: 0,
  enabledNextStep: false,
  selectedGroup: "",
  onChangeSelectedGroup: (input: string) => {},
  selectedDisease: null,
  selectedMedication: null,
  searchedMedication: "",
  onDiseaseSelect: (
    disease: Disease | null,
    medication: Medication | null
  ) => {},
  onMedicationSearch: (input: string) => {},
  allowSendToEmail: false,
  allowDownload: true,
  sendToEmails: [],
  isUBCMember: false,
  onChangeAllowSendToEmail: (e: ChangeEvent<HTMLInputElement>) => {},
  onChangeAllowDownload: (e: ChangeEvent<HTMLInputElement>) => {},
  onChangeSendToEmails: (emails: string[]) => {},
  onChangeIsUBCMember: (e: ChangeEvent<HTMLInputElement>) => {},
  changeEnabledNextStep: (input: boolean = true) => {},
  goToNextStep: () => {},
  goToPreviousStep: () => {},
  routeId: "",
  isCompleting: false,
  isCompleted: false,
  changeIsCompletedState: (input: boolean) => {},
  changeIsCompletingState: (input: boolean) => {},
}

// Context with initial "FormProvider" state.
export const FormContext = createContext(initialState)

// Get default "patient" & "physician" step names from environment.
const patientStepIds = environmentVariable("patientSteps") as string[]
const physicianStepIds = environmentVariable("physicianSteps") as string[]

/**
 * Type definition for props required for FormContext.Provider.
 *
 * @interface
 */
interface FormProviderProps {
  routeId: string
  children: ReactNode
}

/**
 * Component used for keeping track of the all fields inside "Forms",
 * "Group", "Medication", & "Download" component.
 *
 * Used on "forms" page.
 *
 * @param {FocusProviderProps} param
 *
 * @returns {JSX.Element}
 */
const FormProvider = ({ routeId, children }: FormProviderProps) => {
  const [totalSteps, setTotalSteps] = useState<number>(0) // Total number of form steps based on user type.
  const [activeStepId, setActiveStepId] = useState<string>("") // Current step/section user is on's name.
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0) // Current step/section user is on's number.
  const [enabledNextStep, setEnabledNextStep] = useState<boolean>(false) // Enable "Next" button in Footer.
  const [searchedMedication, setSearchedMedication] = useState<string>("") // Save searched value for Medication section.
  const [selectedGroup, setSelectedGroup] = useState<string>("") // Only valid for Group section.
  const [selectedDisease, setSelectedDisease] = useState<Disease | null>(null) // Only valid for Medication section.
  const [
    selectedMedication,
    setSelectedMedication,
  ] = useState<Medication | null>(null) // Only valid for Medication section.
  const [allowSendToEmail, setAllowSendToEmail] = useState<boolean>(false) // Only valid for Download section.
  const [allowDownload, setAllowDownload] = useState<boolean>(true) // Only valid for Download section.
  const [isUBCMember, setIsUBCMember] = useState<boolean>(false) // Only valid for Download section.
  const [sendToEmails, setSendToEmails] = useState<string[]>([]) // Only valid for Download section.
  const [isCompleting, setIsCompleting] = useState<boolean>(false) // Only valid for Download section.
  const [isCompleted, setIsCompleted] = useState<boolean>(false) // Only valid for Download section.

  /**
   * Set "selectedGroup" state. Used for "onChange" listener
   * for "CustomAutocomplete" component inside "Group" section.
   *
   * Only required for "Group" section.
   *
   * @param {ChangeEvent<HTMLInputElement>} e
   *
   * @returns {void}
   */
  const onChangeSelectedGroup = (input: string) => {
    const trimmedValue = input.trim()

    if (!trimmedValue) {
      return
    }

    setSelectedGroup(trimmedValue)
  }

  /**
   * Set "isComplete" state. Used for tracking "Forms" component's
   * completion state & update "Button" states in the
   * "FormLayout" footer & "Download" seciton of the site.
   *
   * Only required for "Download" section & "Footer" inside "FormLayout".
   *
   * @param {ChangeEvent<HTMLInputElement>} e
   *
   * @returns {void}
   */
  const changeIsCompletingState = (input: boolean): void => {
    setIsCompleting(input)
  }

  /**
   * Set "isComplete" state. Used for tracking "Forms" component's
   * completion state & update "CustomProgressBar" state in the
   * "FormLayout" footer.
   *
   * Only required for "Footer" inside "FormLayout".
   *
   * @param {ChangeEvent<HTMLInputElement>} e
   *
   * @returns {void}
   */
  const changeIsCompletedState = (input: boolean): void => {
    setIsCompleted(input)
  }

  /**
   * Set "allowSendToEmail" state. Used for "onChange" listener
   * for "CustomCheckbox" component inside "Download" section.
   *
   * Only required for "Download" section.
   *
   * @param {ChangeEvent<HTMLInputElement>} e
   *
   * @returns {void}
   */
  const onChangeAllowSendToEmail = (e: ChangeEvent<HTMLInputElement>): void => {
    setAllowSendToEmail(e.currentTarget.checked)
  }

  /**
   * Set "allowDownload" state. Used for "onChange" listener
   * for "CustomCheckbox" component inside "Download" section.
   *
   * Only required for "Download" section.
   *
   * @param {ChangeEvent<HTMLInputElement>} e
   *
   * @returns {void}
   */
  const onChangeAllowDownload = (e: ChangeEvent<HTMLInputElement>): void => {
    setAllowDownload(e.currentTarget.checked)
  }

  /**
   * Set "isUBCMember" state. Used for "onChange" listener
   * for "CustomCheckbox" component inside "Download" section.
   *
   * Only required for "Download" section.
   *
   * @param {ChangeEvent<HTMLInputElement>} e
   *
   * @returns {void}
   */
  const onChangeIsUBCMember = (e: ChangeEvent<HTMLInputElement>): void => {
    setIsUBCMember(e.currentTarget.checked)
  }

  /**
   * Set "sendToEmails" state. Used for "onChange" listener
   * for "CustomAutoComplete" component inside "Download" section.
   *
   * Only required for "Download" section.
   *
   * @param {string[]} emails
   *
   * @returns {void}
   */
  const onChangeSendToEmails = (emails: string[]): void => {
    const correctEmails: string[] = []

    emails.forEach(email => {
      const trimmedValue = email.trim()

      if (validateEmail(trimmedValue)) {
        correctEmails.push(trimmedValue)
      }
    })

    setSendToEmails(correctEmails)
  }

  /**
   * Set "selectedDisease" & "selectedMedication" state.
   *
   * Only required for "Medications" section.
   *
   * @param {Disease|null} disease
   * @param {Medication|null} medication
   *
   * @returns {void}
   */
  const onDiseaseSelect = (
    disease: Disease | null,
    medication: Medication | null
  ): void => {
    setSelectedDisease(disease)

    setSelectedMedication(medication)

    changeEnabledNextStep()
  }

  /**
   * Updated "searchedMedication" state.
   *
   * @param {string} input
   *
   * @returns {void}
   */
  const onMedicationSearch = (input: string): void => {
    setSearchedMedication(input)
  }

  /**
   * Updated "enabledNextStep" state.
   *
   * @param {boolean} input
   *
   * @returns {void}
   */
  const changeEnabledNextStep = (input: boolean = true): void => {
    setEnabledNextStep(input)
  }

  /**
   * Update "activeStepNumber" to next step.
   *
   * @returns {void}
   */
  const goToNextStep = (): void => {
    setActiveStepNumber((previous: number) => previous + 1)
  }

  /**
   * Update "activeStepNumber" to previous step.
   *
   * @returns {void}
   */
  const goToPreviousStep = (): void => {
    setActiveStepNumber((previous: number) => previous - 1)
  }

  /**
   * On "activeStepNumber" state change, update "activeStepId"
   */
  useEffect(() => {
    if (routeId === "physicians") {
      setActiveStepId(physicianStepIds[activeStepNumber])
    } else {
      setActiveStepId(patientStepIds[activeStepNumber])
    }
  }, [activeStepNumber])

  /**
   * On "routeId" state change, update "activeStepNumber" to first step
   * and "totalSteps" & "activeStepId" to default states.
   */
  useEffect(() => {
    const firstStep = 0

    setActiveStepNumber(firstStep)

    if (routeId === "physicians") {
      setTotalSteps(physicianStepIds.length)
      setActiveStepId(physicianStepIds[firstStep] || "")
    } else {
      setTotalSteps(patientStepIds.length)
      setActiveStepId(patientStepIds[firstStep] || "")
    }
  }, [routeId])

  /**
   * On "activeStepId" state change, update "enabledNextStep" to false/true
   * depending on the actions required on the step.
   */
  useEffect(() => {
    switch (activeStepId) {
      case "group":
        // Keep disabled until user selects a group.
        setEnabledNextStep(!!selectedGroup)

        break
      case "medications":
        // Keep disabled until user selects a medication & disease.
        setEnabledNextStep(!!selectedMedication && !!selectedDisease)

        break
      case "howTo":
        // No user action required so set true by default.
        setEnabledNextStep(true)

        break
      default:
        // Keep disabled until user completes an action.
        setEnabledNextStep(false)

        break
    }
  }, [activeStepId])

  return (
    <FormContext.Provider
      value={{
        totalSteps,
        activeStepId,
        activeStepNumber,
        enabledNextStep,
        selectedGroup,
        onChangeSelectedGroup,
        selectedDisease,
        selectedMedication,
        searchedMedication,
        onDiseaseSelect,
        onMedicationSearch,
        allowSendToEmail,
        allowDownload,
        sendToEmails,
        isUBCMember,
        onChangeAllowSendToEmail,
        onChangeAllowDownload,
        onChangeSendToEmails,
        onChangeIsUBCMember,
        changeEnabledNextStep,
        goToNextStep,
        goToPreviousStep,
        routeId,
        isCompleting,
        isCompleted,
        changeIsCompletedState,
        changeIsCompletingState,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const Consumer = FormContext.Consumer
export const Provider = FormProvider

/** @jsx jsx */
import "./../normalizer.css"
import Header from "./header"
import { jsx } from "theme-ui"
import Footer from "./footer"
import Forms from "../../forms"
import { ReactNode } from "react"
import { WindowLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { MFullHeight } from "../../../helpers/others"
import { Provider as FormProvider } from "../../forms/context"
import { Provider as FocusProvider } from "../../forms/context/focus"

/**
 * Type definition for props required for "FormLayout" component.
 *
 * @interface
 */
interface LayoutProps {
  location: WindowLocation
  children: ReactNode
}

/**
 * Type definition for props required to know the user type
 * trying to access child layout elements ("patient" or "physician").
 *
 * @interface
 */
export interface FormUserProps {
  userType: string
}

/**
 * Component rendering child components with form "Header" & "Footer".
 *
 * @param {LayoutProps} param
 *
 * @returns {JSX.Element}
 */
const FormLayout = ({ location, children }: LayoutProps): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // To know which page to redirect back to
  const { routeId } = location.state || { routeId: "homepage" }

  const userType = routeId === "physicians" ? "physicians" : "patients"

  return (
    <div sx={{ fontFamily: "body", bg: "white", m: 0 }}>
      <Header routeId={routeId} title={data.site.siteMetadata.title} />
      <FormProvider routeId={routeId}>
        <FocusProvider>
          <main>
            {children}
            <MFullHeight />
            <Forms userType={userType} />
          </main>
          <Footer userType={userType} />
        </FocusProvider>
      </FormProvider>
    </div>
  )
}

export default FormLayout

import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../../helpers/theme"

export const howToContainerStyles: SxStyleProp = {
  height: "100%",
  overflow: "visible",
  alignItems: "center",
  flexDirection: "column",
  py: createResponsiveStyles(6, "5%"),
  mb: createResponsiveStyles(6, 9),
  width: createResponsiveStyles("100%", "90%", "80%"),
}

export const howToTextContainerStyles: SxStyleProp = {
  width: "100%",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
}

export const howToTitleStyles: SxStyleProp = {
  pb: 2,
  textAlign: "center",
  fontWeight: "bold",
  fontFamily: "heading",
  fontSize: createResponsiveStyles(4, 6),
}

export const howToTextStyles: SxStyleProp = {
  px: 2,
  textAlign: "center",
  whiteSpace: "pre-line",
  pb: createResponsiveStyles(2, 7),
  width: createResponsiveStyles("100%", "100%", "60%"),
}

export const howToStepContainerStyles: SxStyleProp = {
  width: "100%",
  flexDirection: createResponsiveStyles("column", "row"),
  pb: createResponsiveStyles(9, "0"),
}

export const howToStepStyles: SxStyleProp = {
  width: "100%",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  px: createResponsiveStyles(9, 4, 9),
  "&:nth-of-type(2)": {
    svg: {
      maxWidth: createResponsiveStyles("140px", "130px", "100%"),
      height: "auto",
    },
  },
}

export const howToStepIconStyles: SxStyleProp = {
  minHeight: "150px",
  alignItems: "flex-end",
}

export const howToStepTextStyles: SxStyleProp = {
  width: "100%",
  whiteSpace: "pre-line",
  mt: createResponsiveStyles(4, 4, 3),
}

import { SxStyleProp } from "theme-ui"
import { isIE } from "react-device-detect"
import { createResponsiveStyles } from "../../../../helpers/theme"

export const medicationsContainerStyles: SxStyleProp = {
  height: "100%",
  alignItems: "center",
  flexDirection: "column",
  overflowY: createResponsiveStyles("scroll", "visible"),
  width: createResponsiveStyles("100%", "80%", "46%"),
  mb: createResponsiveStyles("48px", "0"),
}

export const medicationsTextContainerStyles: SxStyleProp = {
  width: "100%",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
}

export const medicationsTitleStyles = (
  windowHeight: number = 0
): SxStyleProp => {
  const paddingTopForSmallHeight =
    windowHeight && windowHeight <= 620 ? "0%" : "5%"

  return {
    pb: 2,
    px: 2,
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "heading",
    pt: createResponsiveStyles(
      6,
      paddingTopForSmallHeight, // Only on load
      paddingTopForSmallHeight, // Only on load
      1
    ),
    fontSize: createResponsiveStyles(4, 6),
  }
}

export const medicationsTextStyles: SxStyleProp = {
  px: 2,
  textAlign: "center",
  whiteSpace: "pre-wrap",
  mb: createResponsiveStyles(2, 5),
  width: createResponsiveStyles("100%", "100%", "120%"),
}

export const medicationsSearchContainerStyles: SxStyleProp = {
  mt: isIE ? 7 : "0", // IE Fix
  width: "100%",
  height: "100%",
  flexDirection: "column",
  justifyContent: "space-between",
  ".MuiFormControl-root": {
    mx: createResponsiveStyles(5, "0"),
    my: createResponsiveStyles(5, "0", "0", 2),
  },
}

export const medicationsListContainerStyles: SxStyleProp = {
  mt: "-1px",
  width: "100%",
  height: "100%",
  minHeight: "150px",
  overflowY: "scroll",
  borderStyle: "solid",
  borderColor: "primary",
  mb: createResponsiveStyles("0", 10),
  borderWidth: createResponsiveStyles("0px", "2px"),
  boxShadow: createResponsiveStyles(`0 2px 4px 4px rgba(0, 0, 0, 0.1)`, "none"),
}

export const medicationsListErrorContainerStyles: SxStyleProp = {
  mt: "-1px",
  width: "100%",
  height: "100%",
  overflowY: "scroll",
  borderStyle: "solid",
  alignItems: "center",
  borderColor: "primary",
  flexDirection: "column",
  justifyContent: "flex-start",
  pt: createResponsiveStyles(6, 8),
  pb: 6,
  mb: createResponsiveStyles("0", 10),
  borderWidth: createResponsiveStyles("0px", "2px"),
  boxShadow: createResponsiveStyles(`0 2px 4px 4px rgba(0, 0, 0, 0.1)`, "none"),
}

export const medicationsListErrorTextStyles: SxStyleProp = {
  width: "100%",
  px: createResponsiveStyles(6, 6, 8, 4),
  textAlign: "center",
  ".SearchText": {
    textDecoration: "underline",
  },
}

import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../helpers/theme"

export const formsContainerStyles = (formSection?: string): SxStyleProp => {
  let height: string | any[] = "auto"

  if (formSection === "medications") {
    height = createResponsiveStyles(
      "calc(var(--vh, 1vh) * 100)",
      "calc(100vh - 174px)",
      "calc(100vh - 204px)",
      "calc(var(--vh, 1vh) * 100)"
    )
  }

  return {
    width: "100%",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    zIndex: "body", // IE Fix
    height,
    minHeight: createResponsiveStyles("300px", "500px"),
  }
}

import { isAndroid } from "react-device-detect"
import React, { createContext, useState, ReactNode, useEffect } from "react"

/**
 * Type definition for initial "FocusProvider" state.
 *
 * @interface
 */
export interface FocusState {
  isFocused: boolean
  isKeyboardOpen: boolean
  setInputFocusState: (input: boolean) => void
}

/**
 * Constant with initial "FocusProvider" state.
 *
 * @constant
 */
const initialState: FocusState = {
  isFocused: false,
  isKeyboardOpen: false,
  setInputFocusState: (input: boolean) => {},
}

// Context with initial "FocusProvider" state.
export const FocusContext = createContext(initialState)

/**
 * Type definition for props required for FocusContext.Provider.
 *
 * @interface
 */
export interface FocusProviderProps {
  children: ReactNode
}

/**
 * Component used for keeping track of the input field "focus" state.
 * On some mobile phones (eg. Samsung Galaxy S5), the fixed buttons
 * floating in the "bottom" get shifted up when the keyboard opens.
 * This causes the parts of the page to not stay visible
 * (for eg. Medications page).
 *
 * So we use the component to change the focus state & hide the buttons
 * in case the keyboard is open.
 *
 * Used on "forms" page.
 *
 * @param {FocusProviderProps} param
 *
 * @returns {JSX.Element}
 */
const FocusProvider = ({ children }: FocusProviderProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>(false)
  const [originalWindowHeight, setOriginalWindowHeight] = useState<number>(0)

  /**
   * Set "isFocused" state.
   *
   * @param {boolean} input
   *
   * @returns {void}
   */
  const setInputFocusState = (input: boolean): void => {
    setIsFocused(input)
  }

  /**
   * Check if native keyboard is hidden or showing.
   *
   * Note: Fixes issues with "Android" devices &
   * is therefore valid only for "Android"
   *
   * @function
   */
  const onKeyboardShowHide = () => {
    // Checks if the user is still focused on the field
    // since they can press the hide keyboard button
    // and still keep input field focus
    if (
      window.innerHeight < originalWindowHeight - 80 &&
      isAndroid &&
      isFocused
    ) {
      setIsKeyboardOpen(true)
    }

    // If any input field is not being focused on,
    // keyboard is considered as closed.
    if (
      (window.innerHeight >= originalWindowHeight - 80 &&
        isAndroid &&
        isFocused) ||
      !isFocused
    ) {
      setIsKeyboardOpen(false)
    }
  }

  /**
   * On component mount, set the "originalWindowHeight" value.
   */
  useEffect(() => {
    setOriginalWindowHeight(window.innerHeight)
  }, [])

  /**
   * On "focus" state change, check if the keyboard is actually
   * open or closed. This is required because in Android devices
   * you have a back button to close the keyboard & in this case
   * the fixed buttons at the bottom should be visible.
   */
  useEffect(() => {
    onKeyboardShowHide()

    window.addEventListener("resize", onKeyboardShowHide)

    return () => {
      document.removeEventListener("resize", onKeyboardShowHide)
    }
  }, [isFocused])

  return (
    <FocusContext.Provider
      value={{ isFocused, isKeyboardOpen, setInputFocusState }}
    >
      {children}
    </FocusContext.Provider>
  )
}

export const Consumer = FocusContext.Consumer
export const Provider = FocusProvider
